export const NoImageSvg = ({ className }) => {
  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/1999/xlink"
      version="1.1"
      id="layer_1"
      viewBox="0 0 32 32"
      styles="enable-background:new 0 0 32 32;"
      xml="preserve"
      className={className}
    >
      <path d="M23.3740845,7.2560425h-0.5732422V3.369873H9.1991577v3.8861694H2.3983154v20.975769L2,28.630127L3.369873,30L30,3.369873  L28.630127,2L23.3740845,7.2560425z M10.947937,19.6821289L10.947937,19.6821289  c-0.1943359-0.553772-0.2914429-1.1366577-0.2914429-1.7390137c0-0.3789062,0.0388184-0.7286987,0.1068726-1.059021  c0.4177246-2.117981,2.0596924-3.7598877,4.1873779-4.1776733C15.2713623,12.6384277,15.6210938,12.5995483,16,12.5995483  c0.602356,0,1.1853027,0.097168,1.7390747,0.2915039L10.947937,19.6821289z M19.2158203,11.4143066  C18.2248535,10.9188232,17.1270142,10.6564941,16,10.6564941c-0.5148926,0-1.010437,0.048584-1.4476318,0.1456909  c-2.885498,0.5732422-5.1200562,2.8078003-5.6932373,5.6835938c-0.097168,0.4468994-0.145752,0.9423828-0.145752,1.4573364  c0,1.1269531,0.2623291,2.2248535,0.7578125,3.2158203l-5.1298218,5.1298218V9.1991577h6.8009033V5.3129272h9.7154541v3.8862305  h0.5732422L19.2158203,11.4143066z" />
      <polygon points="8.8689575,28.630127 29.6016846,28.630127 29.6016846,7.8973999 27.6586304,7.8973999 27.6586304,26.6870728   8.8689575,26.6870728 " />
      <path d="M16,23.2866211c-0.6849976,0-1.3387451-0.1257324-1.9431152-0.3738403l-0.8984985-0.3690796l-0.7381592,1.7969971  l0.8984985,0.3690796C14.1584473,25.0546875,15.06073,25.2297363,16,25.2297363  c4.0180664,0,7.2866211-3.2685547,7.2866211-7.2866211c0-0.9393311-0.1750488-1.8416138-0.5198975-2.6812744l-0.3690796-0.8984985  l-1.7969971,0.7381592L20.9697266,16c0.2481079,0.6043701,0.3737793,1.2580566,0.3737793,1.9431152  C21.3435059,20.8895264,18.9464111,23.2866211,16,23.2866211z" />
    </svg>
  );
};
