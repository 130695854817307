import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import BusinessServices from "../services/BusinessServices";
import Spinner from "../components/general/Spinner";
import { NoImageSvg } from "../assets/NoImageSvg";
import { ArrowLeftSvg } from "../assets/svg/ArrowLeftSvg";

const Vendor = () => {
  const params = useParams();
  const vendorId = params?.vendorId;

  const date = (value) => {
    const newDate = new Date(value);
    const month = newDate
      .toLocaleString("default", { month: "long" })
      .toLowerCase(); // Get month name (lowercase)
    const year = newDate.getFullYear();
    return `${month} ${year}`;
  };

  const {
    data: vendor,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["vendor-details", vendorId],
    queryFn: async () => {
      const response = await BusinessServices.fetchOneVendor(vendorId);
      return response?.data;
    },
    enabled: !!vendorId,
  });

  return (
    <div className="flex flex-col items-center justify-start mt-24">
      {isError && <p className="text-[red] text-center ">There was an error</p>}
      {isLoading && <Spinner />}
      {vendor && (
        <>
          <Link
            to="/vendors"
            className="mr-auto ml-10 md:ml-24
          flex flex-row items-center justify-center"
          >
            {" "}
            <ArrowLeftSvg className="" /> Back
          </Link>
          {vendor?.logo && (
            <img
              alt="vendor-logo"
              src={`${vendor?.logo}`}
              className="h-48 w-48 rounded-md object-cover"
            />
          )}

          {!vendor?.logo && (
            <div
              className="bg-gray-200 h-48 w-48 rounded-md flex
          flex-col items-center justify-center color-[gray] mb-5"
            >
              <NoImageSvg className="fill-[gray] h-12 w-12" />
            </div>
          )}
          <RowItem title="name" value={vendor?.name} />
          <RowItem
            title="phone number"
            value={
              vendor?.mpesa_phone_no ||
              vendor?.users?.phone_number.replace("+254", "07") ||
              "__"
            }
          />
          <RowItem title="category" value={vendor?.business_categories?.name} />
          <RowItem title="date joined" value={date(vendor?.createdAt)} />
        </>
      )}
    </div>
  );
};

export default Vendor;

const RowItem = ({ title, value }) => {
  return (
    <div
      className="flex flex-row
    items-center justify-between w-3/4 md:w-1/3 mb-3"
    >
      <p className="font-semibold capitalize text-left"> {title} </p>
      <p className="text-right"> {value} </p>
    </div>
  );
};
