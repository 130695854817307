import { usePagination } from "./usePagination";
import BusinessServices from "../services/BusinessServices";
import { useCallback, useMemo, useRef, useState } from "react";

export const useVendors = () => {
  const observer = useRef();
  const [enabled, setEnabled] = useState(true);
  const fetchVendors = async ({ pageParam = 0 }) => {
    return await BusinessServices.fetchAllVendors(pageParam);
  };

  const {
    data,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = usePagination(
    {
      queryKey: ["vendors"],
      queryFn: fetchVendors,
      enabled,
    },
    "vendors fetch  error:"
  );

  const vendors = useMemo(() => {
    let allPages = [];

    if (data?.pages?.length > 0) {
      for (const obj of data?.pages) {
        if (obj?.data) {
          allPages.push(...obj?.data);
        } else {
          allPages.push(...obj);
        }
      }
    }

    return allPages;
  }, [data?.pages]);

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver(async (entries) => {
        const count = data?.pages[0].totalCount;
        if (
          entries[0].isIntersecting &&
          hasNextPage &&
          !isLoading &&
          !isFetchingNextPage &&
          !isRefetching &&
          !isFetching &&
          vendors?.length < count
        ) {
          if (vendors.length >= count) {
            setEnabled(false); // Disable fetching if vendor length reaches totalCount
            return;
          }
          await fetchNextPage();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading]
  );

  return {
    vendors,
    containerRef: lastElementRef,
    isFetchingNextPage,
    isFetching,
    isLoading,
    isRefetching,
    totalCount: data?.pages[0]?.totalCount,
  };
};
