export const TriangleRightSvg = () => {
  return (
    <svg
      height="15"
      id="triangle-right"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 4 V28 L26 16 z" />
    </svg>
  );
};
