import { useVendors } from "../hooks/useVendors";
import { Link } from "react-router-dom";
import { TriangleRightSvg } from "../assets/svg/TriangleRightSvg";
import Helper from "../utils/Helper";
import React from "react";
import Spinner from "../components/general/Spinner";

const Vendors = () => {
  const { vendors, containerRef, isFetchingNextPage, totalCount, isLoading } =
    useVendors();

  return (
    <div className="flex flex-col items-center px-12">
      <h5
        className={
          "mt-24 flex justify-center mb-6 md:mb-10" +
          " font-times text-[25px] md:text-[40px]" +
          " font-[600] leading-8 md:leading-[48px]" +
          " text-grey_800 text-center md:text-left"
        }
      >
        Pickup Mtaani Vendors
      </h5>
      <p
        className="flex flex-row items-center justify-between capitalize
      w-full md:w-1/2 mb-4 font-semibold"
      >
        <span> total </span>
        <span className="text-[red]">
          {" "}
          {Helper.numberToK(totalCount) || 0}{" "}
        </span>
      </p>

      {isLoading && <Spinner />}

      {Array.isArray(vendors)
        ? vendors.map((vendor, index) => (
            <Link
              to={`${vendor?.id}`}
              key={vendor?.id}
              ref={containerRef}
              className="bg-gray-200 p-4 rounded-lg
                       shadow-md mb-4 w-full md:w-1/2 mb-2 font-semibold flex
                       flex-row items-center justify-start shadow-md
                       hover:shadow-lg"
              id={`vendor-${index + 1}`}
            >
              <TriangleRightSvg />
              {vendor?.name}
            </Link>
          ))
        : null}

      {isFetchingNextPage && (
        <p className="text-center text-[gray] mb-3">...fetching more vendors</p>
      )}
    </div>
  );
};

export default Vendors;
