import React, { useContext, useState } from "react";
import Map from "../components/map";
import FloatingCards from "../components/tracker/FloatingCards";
import OrderDetails from "../components/tracker/order/OrderDetails";
import ReceiptDetails from "../components/tracker/package_details/ReceiptDetails";
import JourneyPane from "../components/tracker/journey/JourneyPane";
import { Context } from "../context/StorageContext";
import FeedbackDialog from "../components/feedback/FeedbackDialog";
import { MessageSvg } from "../assets/svg";

const Tracker = () => {
  const [showReceipt, setShowReceipt] = useState(false);
  const [showJourney, setShowJourney] = useState(false);
  const { trackingData } = useContext(Context);

  const handleReceiptClick = () => {
    setShowReceipt((prev) => !prev);
    setShowJourney(false);
  };
  const handleJourneyClick = () => {
    setShowJourney((prev) => !prev);
    setShowReceipt(false);
  };

  return (
    <div
      className={
        " bg-slate-100 flex flex-col gap-5 min-h-[calc(100vh-96px)] relative h-screen"
      }
    >
      {HIDE_MAP_STATES.includes(trackingData?.status) && (
        <div className={"absolute inset-0 bg-gray-900 opacity-95 z-20"} />
      )}

      {/*  Tracking title */}

      <Map />

      <FloatingCards />

      <OrderDetails
        showJourney={showJourney}
        showReceipt={showReceipt}
        handleReceiptClick={handleReceiptClick}
        handleViewJourney={handleJourneyClick}
      />

      <ReceiptDetails
        handleClose={handleReceiptClick}
        isOpen={showReceipt && !showJourney}
      />

      <JourneyPane
        isOpen={showJourney && !showReceipt}
        handleClose={handleJourneyClick}
      />

      <FeedbackDialog type='specific'>
        <button
          className={`rounded-full bg-primary p-2 fixed top-96
         left-5 shadow-lg z-50 hover:opacity-80`}
        >
          <MessageSvg />
        </button>
      </FeedbackDialog>
    </div>
  );
};

export default Tracker;

/**
 * @typedef {{
 * category:boolean,
 * location:Location,
 * name:string,
 * status:string,
 * locationName:string
 * }} DeliveryPackage
 */

/**
 * @typedef {{
 * lng:float,
 * lat:float,
 * }} Location
 */

let HIDE_MAP_STATES = [
  "rejected",
  "collected",
  "in_transit_to_external_provider",
  "with_external_provider",
];
