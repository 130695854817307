import axios from "axios";
import { BASE_URL } from "../Config";

const fetchAllVendors = async (page, limit = 20) => {
  const response = await axios.get(
    `${BASE_URL}/vendors?page=${page}&limit=${limit}`
  );

  return response?.data;
};
const fetchOneVendor = async (vendorId) => {
  const response = await axios.get(`${BASE_URL}/vendors/${vendorId}`);

  return response?.data;
};

const BusinessServices = {
  fetchOneVendor,
  fetchAllVendors,
};

export default BusinessServices;
