import { useInfiniteQuery } from "react-query";
import Helper from "../utils/Helper";
import useError from "./useError";

export const usePagination = (options, errorMessage) => {
  const handleError = useError();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isRefetching,
    isLoading
  } = useInfiniteQuery({
    ...options,
    onError: err => handleError(err, errorMessage),
    getNextPageParam: lastPage => {
      const pageLimit = lastPage?.pageSize;
      const totalItems = lastPage?.totalCount;
      const currentPage = lastPage?.pageNumber;

      return Helper.getNextPrevPage(pageLimit, totalItems, currentPage, 'next');
    },
    getPreviousPageParam: lastPage => {
      const pageLimit = lastPage?.pageSize;
      const totalItems = lastPage?.totalCount;
      const currentPage = lastPage?.pageNumber;

      return Helper.getNextPrevPage(pageLimit, totalItems, currentPage, 'prev');
    },
  });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isRefetching,
    isLoading
  };
};
